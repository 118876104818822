
// Bring in Bootstrap
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";


/* =ITS Home
----------------------------------------------------------*/

.front{

  /**
  * Homepage Layout BY LOGON STATE
  */

  // Logged-IN Only
  &.logged-in{
    // How Can We Help You, Alerts Pane
    .header-section{
      .panels-ipe-portlet-wrapper{
        @include make-xs-column(12);
      }
    }
    // News Section
    .section-1{
      .panels-ipe-portlet-wrapper{
        @include make-xs-column(12);

        // Both News Panes
        &:nth-of-type(2),
        &:nth-of-type(3){
          @include make-md-column(6);
        }

        // News Left Large
        &:nth-of-type(2){
          @include breakpoint($md){
            padding-right: 4px;
          }
        }
        // News Right Small
        &:nth-of-type(3){
          @include breakpoint($md){
            padding-left: 4px;
          }
        }

      }
    }
    // Services Section
    .section-2{
      .panels-ipe-portlet-wrapper{
        @include make-xs-column(12);
        // Audience Pane
        &:nth-of-type(1){
          @include make-md-column(8);
          @include breakpoint($md){
            border-bottom: 2px solid $wcm-border-gray;
            border-left: 2px solid $wcm-border-gray;
          }
        }
        // Service Status Pane
        &:nth-of-type(2){
          @include make-md-column(4);
          @include breakpoint($sm) {
            border: 2px solid $wcm-border-gray;
          }
          @include breakpoint($md){
            margin-top: 3rem;
          }
        }
      }
    }
    // Learning Section
    .section-3{
      .panels-ipe-portlet-wrapper{
        @include make-md-column(4);
        
        &:first-of-type{
          @include make-xs-column(12);
        }
      }
    
    }

    .footer-section{
      .panels-ipe-portlet-wrapper{
        @include container-wrap();
      }
    }

  }// END Logged-IN

  //* Logged-OUT Only
  &.not-logged-in{
    // How Can We Help You, Alerts Pane
    .header-section{
      .panel-pane{
        @include make-xs-column(12);
      }
    }
    // News Section
    .section-1{
      .panel-pane{
        @include make-xs-column(12);
        // Both News Panes
        &:nth-of-type(2),
        &:nth-of-type(3){
          @include make-md-column(6);
        }
        // News Left Large
        &:nth-of-type(2){
          @include breakpoint($md){
            padding-right: 4px;
          }
        }
        // News Right Small
        &:nth-of-type(3){
          @include breakpoint($md){
            padding-left: 4px;
          }
        }

        // Services Section Header
        &.pane-bundle-text{
          &:last-of-type{
            h2{
              margin-top: 30px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    //Services Section
    .section-2{
      .panel-pane{
        @include make-xs-column(12);
        // Audience pane
        &:nth-of-type(1){
          @include make-md-column(8);
          @include breakpoint($md){
            border-bottom: 2px solid $wcm-border-gray;
            border-left: 2px solid $wcm-border-gray;
          }
        }
        // Service Status Pane
        &:nth-of-type(2){
          @include make-md-column(4);
          @include breakpoint($sm) {
            border: 2px solid $wcm-border-gray;
          }

          @include breakpoint($md){
            margin-top: 3rem;
          }
          
        }
      }
    }
    // Learning Section
    .section-3{
      .panel-pane{
        @include make-xs-column(12);
        @include make-md-column(4);

        &:first-of-type{
          @include make-xs-column(12);
        }

      }
    }

    .footer-section{
      .panel-pane{
        @include container-wrap();
      }
    }

  } // END Logged-OUT


  /**
  * Homepage STATELESS styles
  */
  section{

    .panel-pane{
      &.pane-bundle-text{
        &:first-of-type{
          h2{
            margin-top: 30px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  //not sure why row gutter is off by 5px;, so fixing it here
  .row{
    margin-left: -15px;
    margin-right: -15px;
  }
  // Make the Header a container
  .header-section{
    @include container-wrap();
  }
  // Main wraps the sections between Header and Footer
  main{
    @include container-wrap();
  }
  // Footer is full width container
  .footer-section{
    @include container-fixed();
  }

  // News Section
  .section-1{
    .featured-news-teaser{
      margin-bottom: 2rem;

      img{
        transition: all 0.1s linear;
      }
      
      &:hover{
        img{
          transform: scale(1.1);
          transition: all 0.1s linear;
        }
      }

      @include breakpoint($md){
        margin-bottom: unset;
      }
    }

    .panel-pane{
      margin-top: 0;

      &:first-of-type{
        margin-bottom: 1rem;
      }

      &.pane-news-panel-pane-5{
        margin-bottom: 0;
      }
    }
  }

  /**
  * Recent News
  *
  */
  // Both Panels
  .pane-news-panel-pane-5,
  .pane-news-panel-pane-6 {
    .pane-title {
      line-height: 1.5;
      margin-bottom: 15px;
    }

    .view-content{
      margin-top: 0;
      border: none;

      .featured-news-teaser{
        position: relative;
        overflow: hidden;
  
        &::after{
          display: block;
          content: '';
          padding-top: 75%;
        }
  
        .teaser__image{
          padding: 0;
          margin: 0;
  
          img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
  
        .teaser__detail{
          position: absolute;
          bottom: 0;
        }
      }

    }
  }

  // Large Main Left Panel
  .pane-news-panel-pane-6{

    .teaser__detail{
      color: $wcm-white;
      margin-right: 2rem;
      margin-bottom: 2rem;
      margin-left: 2rem;
      background: rgba(0,0,0,0.8);

      .teaser-title{
        a{
          color: $wcm-white;

          &::after{
            color: $wcm-white;
          }
        }  
      }

    }
  }

  // Right Panel 5 smaller
  .pane-news-panel-pane-5{

    .view-content{
      padding: 0;

      .featured-news-teaser{
        background-color: $wcm-border-gray;

        @include breakpoint($md){
          max-width: 48%;
          margin: .5%;
        }

        .teaser__detail{
          margin: 0;
          color: $wcm-dark-orange;
          font-weight: bold;
          width: 100%;

          &::before{
            background-color: $wcm-white;
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .teaser-title{
            position: relative;
            font-size: $font-size-h4;
          }
        }

        @include breakpoint($md) {
          display: inline-block;
          width: 49.5%;
        }
      }
    }
  }

  // Service Section
  .section-2{

    > .row{
      @include breakpoint($md){
        border-top: 2px solid $wcm-border-gray;
        border-right: 2px solid $wcm-border-gray;
      }
      margin: 0;
    }

  }
  
  /* 
  * Active Service Alerts
  */
  .pane-alerts-panel-pane-4{
    text-align: center;

    .pane-title{
      color: $wcm-white;
      background-color: $wcm-red;
      padding: 1rem 0;

      &::before{
        @include fontello();
        content: '\e812 ';
        margin-right: 1rem;
      }
    }

    .view-content{
      display: inline-block;

      .alerts{
        border-bottom: 1px solid $wcm-border-gray;
        padding: .75rem 0;

        .views-field-title{
          font-family: $wcm-bold;
          font-size: $font-size-h4;
        }
      }  
    }
    
    .view-footer{
      margin-top: 1.5rem;
    }
  }

  /**
  * Service Category List
  *
  */
  .item-list ul .service-category-list {
    @include breakpoint($sm) {
      // Reset System CSS
      margin: 0;
      padding: 0 15px 5px 0;
    }
  }

  .service-category-list {
    line-height: 1.5;
    font-size: 14px;
    @include breakpoint($sm) {
      width: 50%;
      float: left;
      display: inline;
    }
  }


  /**
  * Service Links
  *
  */
  .service-link {
    margin-bottom: 15px;
  }

  .service-link__title {
    font-size: 18px;
    font-weight: 700;
  }

  // Learning Section
  .section-3{
    .panel-pane{
      // The section header
      &:first-of-type{
        margin-bottom: 1rem;
      }
    }

    .pane-bundle-image{
      .fieldable-panels-pane{

        position: relative;
        overflow: hidden;

        img{
          transition: all 0.1s linear;
        }

        &:hover{
          img{
            transform: scale(1.1);
          }
        }
  
        &::after{
          display: block;
          content: '';
          padding-top: 75%;
        }
        
        .field-basic-image-image{
          img{
            position: absolute;
            top: 0;
            height: 100%;
            object-fit: cover;
          }
  
        }
  
        .field-basic-image-caption{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          background-color: $wcm-bg-gray;
          padding-top: 2rem;
          padding-bottom: 2rem;
  
          h5{
            margin: 0;

            a{
              &::after{
                content: "\e802";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -1.9rem;
                padding: .6rem 1rem;
                font-size: 2rem;
                background-color: $wcm-bg-gray;
                border-radius: 2rem;
              }
            }
          }
  
        }
      }
    }

  }

  .footer-section {
    background: $wcm-dark-orange;
    background-size: cover;
    border-bottom: 1px solid $wcm-white;
    color: #fff;
    margin-top: 30px;
    
    h3 {
      color: #fff;
      text-align: center;
      border-bottom: 1px solid $wcm-border-gray;
      padding: 20px 0;
      margin-bottom: 30px;
      @include breakpoint($md) {
        font-size: $font-size-h2;
      }
    }

    .fieldable-panels-pane{

      .field-basic-text-text{

        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @include breakpoint($md){
          max-width: 70%;
        }

        > p {
          font-size: $font-size-h4;
        }

        blockquote {
          color: $wcm-white;
          font-style: italic;
          border: none;
          font-size: $font-size-base;
          font-weight: normal;

          > p {
              line-height: $font-size-h3;
          }
        }
      }

      
    }

  }

  /**
  * DISTRO Footer
  *
  */
  footer {
    padding-top: 0;
    
  }

}
