/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =ITS Home
----------------------------------------------------------*/
.front {
  /**
  * Homepage Layout BY LOGON STATE
  */
  /**
  * Homepage STATELESS styles
  */
  /**
  * Recent News
  *
  */
  /* 
  * Active Service Alerts
  */
  /**
  * Service Category List
  *
  */
  /**
  * Service Links
  *
  */
  /**
  * DISTRO Footer
  *
  */ }
  .front.logged-in .header-section .panels-ipe-portlet-wrapper {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
  .front.logged-in .section-1 .panels-ipe-portlet-wrapper {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
    .front.logged-in .section-1 .panels-ipe-portlet-wrapper:nth-of-type(2), .front.logged-in .section-1 .panels-ipe-portlet-wrapper:nth-of-type(3) {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
      @media (min-width: 992px) {
        .front.logged-in .section-1 .panels-ipe-portlet-wrapper:nth-of-type(2), .front.logged-in .section-1 .panels-ipe-portlet-wrapper:nth-of-type(3) {
          float: left;
          width: 50%; } }
    @media screen and (min-width: 992px) {
      .front.logged-in .section-1 .panels-ipe-portlet-wrapper:nth-of-type(2) {
        padding-right: 4px; } }
    @media screen and (min-width: 992px) {
      .front.logged-in .section-1 .panels-ipe-portlet-wrapper:nth-of-type(3) {
        padding-left: 4px; } }
  .front.logged-in .section-2 .panels-ipe-portlet-wrapper {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
    .front.logged-in .section-2 .panels-ipe-portlet-wrapper:nth-of-type(1) {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
      @media (min-width: 992px) {
        .front.logged-in .section-2 .panels-ipe-portlet-wrapper:nth-of-type(1) {
          float: left;
          width: 66.66667%; } }
      @media screen and (min-width: 992px) {
        .front.logged-in .section-2 .panels-ipe-portlet-wrapper:nth-of-type(1) {
          border-bottom: 2px solid #dddddd;
          border-left: 2px solid #dddddd; } }
    .front.logged-in .section-2 .panels-ipe-portlet-wrapper:nth-of-type(2) {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
      @media (min-width: 992px) {
        .front.logged-in .section-2 .panels-ipe-portlet-wrapper:nth-of-type(2) {
          float: left;
          width: 33.33333%; } }
      @media screen and (min-width: 768px) {
        .front.logged-in .section-2 .panels-ipe-portlet-wrapper:nth-of-type(2) {
          border: 2px solid #dddddd; } }
      @media screen and (min-width: 992px) {
        .front.logged-in .section-2 .panels-ipe-portlet-wrapper:nth-of-type(2) {
          margin-top: 3rem; } }
  .front.logged-in .section-3 .panels-ipe-portlet-wrapper {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 992px) {
      .front.logged-in .section-3 .panels-ipe-portlet-wrapper {
        float: left;
        width: 33.33333%; } }
    .front.logged-in .section-3 .panels-ipe-portlet-wrapper:first-of-type {
      position: relative;
      float: left;
      width: 100%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
  .front.logged-in .footer-section .panels-ipe-portlet-wrapper {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px; }
    .front.logged-in .footer-section .panels-ipe-portlet-wrapper:before, .front.logged-in .footer-section .panels-ipe-portlet-wrapper:after {
      content: " ";
      display: table; }
    .front.logged-in .footer-section .panels-ipe-portlet-wrapper:after {
      clear: both; }
    @media screen and (min-width: 768px) {
      .front.logged-in .footer-section .panels-ipe-portlet-wrapper {
        width: 750px; } }
    @media screen and (min-width: 992px) {
      .front.logged-in .footer-section .panels-ipe-portlet-wrapper {
        width: 970px; } }
    @media screen and (min-width: 1200px) {
      .front.logged-in .footer-section .panels-ipe-portlet-wrapper {
        width: 1170px; } }
  .front.not-logged-in .header-section .panel-pane {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
  .front.not-logged-in .section-1 .panel-pane {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
    .front.not-logged-in .section-1 .panel-pane:nth-of-type(2), .front.not-logged-in .section-1 .panel-pane:nth-of-type(3) {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
      @media (min-width: 992px) {
        .front.not-logged-in .section-1 .panel-pane:nth-of-type(2), .front.not-logged-in .section-1 .panel-pane:nth-of-type(3) {
          float: left;
          width: 50%; } }
    @media screen and (min-width: 992px) {
      .front.not-logged-in .section-1 .panel-pane:nth-of-type(2) {
        padding-right: 4px; } }
    @media screen and (min-width: 992px) {
      .front.not-logged-in .section-1 .panel-pane:nth-of-type(3) {
        padding-left: 4px; } }
    .front.not-logged-in .section-1 .panel-pane.pane-bundle-text:last-of-type h2 {
      margin-top: 30px;
      margin-bottom: 10px; }
  .front.not-logged-in .section-2 .panel-pane {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
    .front.not-logged-in .section-2 .panel-pane:nth-of-type(1) {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
      @media (min-width: 992px) {
        .front.not-logged-in .section-2 .panel-pane:nth-of-type(1) {
          float: left;
          width: 66.66667%; } }
      @media screen and (min-width: 992px) {
        .front.not-logged-in .section-2 .panel-pane:nth-of-type(1) {
          border-bottom: 2px solid #dddddd;
          border-left: 2px solid #dddddd; } }
    .front.not-logged-in .section-2 .panel-pane:nth-of-type(2) {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
      @media (min-width: 992px) {
        .front.not-logged-in .section-2 .panel-pane:nth-of-type(2) {
          float: left;
          width: 33.33333%; } }
      @media screen and (min-width: 768px) {
        .front.not-logged-in .section-2 .panel-pane:nth-of-type(2) {
          border: 2px solid #dddddd; } }
      @media screen and (min-width: 992px) {
        .front.not-logged-in .section-2 .panel-pane:nth-of-type(2) {
          margin-top: 3rem; } }
  .front.not-logged-in .section-3 .panel-pane {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 992px) {
      .front.not-logged-in .section-3 .panel-pane {
        float: left;
        width: 33.33333%; } }
    .front.not-logged-in .section-3 .panel-pane:first-of-type {
      position: relative;
      float: left;
      width: 100%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
  .front.not-logged-in .footer-section .panel-pane {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px; }
    .front.not-logged-in .footer-section .panel-pane:before, .front.not-logged-in .footer-section .panel-pane:after {
      content: " ";
      display: table; }
    .front.not-logged-in .footer-section .panel-pane:after {
      clear: both; }
    @media screen and (min-width: 768px) {
      .front.not-logged-in .footer-section .panel-pane {
        width: 750px; } }
    @media screen and (min-width: 992px) {
      .front.not-logged-in .footer-section .panel-pane {
        width: 970px; } }
    @media screen and (min-width: 1200px) {
      .front.not-logged-in .footer-section .panel-pane {
        width: 1170px; } }
  .front section .panel-pane.pane-bundle-text:first-of-type h2 {
    margin-top: 30px;
    margin-bottom: 10px; }
  .front .row {
    margin-left: -15px;
    margin-right: -15px; }
  .front .header-section {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px; }
    .front .header-section:before, .front .header-section:after {
      content: " ";
      display: table; }
    .front .header-section:after {
      clear: both; }
    @media screen and (min-width: 768px) {
      .front .header-section {
        width: 750px; } }
    @media screen and (min-width: 992px) {
      .front .header-section {
        width: 970px; } }
    @media screen and (min-width: 1200px) {
      .front .header-section {
        width: 1170px; } }
  .front main {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px; }
    .front main:before, .front main:after {
      content: " ";
      display: table; }
    .front main:after {
      clear: both; }
    @media screen and (min-width: 768px) {
      .front main {
        width: 750px; } }
    @media screen and (min-width: 992px) {
      .front main {
        width: 970px; } }
    @media screen and (min-width: 1200px) {
      .front main {
        width: 1170px; } }
  .front .footer-section {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px; }
    .front .footer-section:before, .front .footer-section:after {
      content: " ";
      display: table; }
    .front .footer-section:after {
      clear: both; }
  .front .section-1 .featured-news-teaser {
    margin-bottom: 2rem; }
    .front .section-1 .featured-news-teaser img {
      transition: all 0.1s linear; }
    .front .section-1 .featured-news-teaser:hover img {
      transform: scale(1.1);
      transition: all 0.1s linear; }
    @media screen and (min-width: 992px) {
      .front .section-1 .featured-news-teaser {
        margin-bottom: unset; } }
  .front .section-1 .panel-pane {
    margin-top: 0; }
    .front .section-1 .panel-pane:first-of-type {
      margin-bottom: 1rem; }
    .front .section-1 .panel-pane.pane-news-panel-pane-5 {
      margin-bottom: 0; }
  .front .pane-news-panel-pane-5 .pane-title,
  .front .pane-news-panel-pane-6 .pane-title {
    line-height: 1.5;
    margin-bottom: 15px; }
  .front .pane-news-panel-pane-5 .view-content,
  .front .pane-news-panel-pane-6 .view-content {
    margin-top: 0;
    border: none; }
    .front .pane-news-panel-pane-5 .view-content .featured-news-teaser,
    .front .pane-news-panel-pane-6 .view-content .featured-news-teaser {
      position: relative;
      overflow: hidden; }
      .front .pane-news-panel-pane-5 .view-content .featured-news-teaser::after,
      .front .pane-news-panel-pane-6 .view-content .featured-news-teaser::after {
        display: block;
        content: '';
        padding-top: 75%; }
      .front .pane-news-panel-pane-5 .view-content .featured-news-teaser .teaser__image,
      .front .pane-news-panel-pane-6 .view-content .featured-news-teaser .teaser__image {
        padding: 0;
        margin: 0; }
        .front .pane-news-panel-pane-5 .view-content .featured-news-teaser .teaser__image img,
        .front .pane-news-panel-pane-6 .view-content .featured-news-teaser .teaser__image img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .front .pane-news-panel-pane-5 .view-content .featured-news-teaser .teaser__detail,
      .front .pane-news-panel-pane-6 .view-content .featured-news-teaser .teaser__detail {
        position: absolute;
        bottom: 0; }
  .front .pane-news-panel-pane-6 .teaser__detail {
    color: #fff;
    margin-right: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    background: rgba(0, 0, 0, 0.8); }
    .front .pane-news-panel-pane-6 .teaser__detail .teaser-title a {
      color: #fff; }
      .front .pane-news-panel-pane-6 .teaser__detail .teaser-title a::after {
        color: #fff; }
  .front .pane-news-panel-pane-5 .view-content {
    padding: 0; }
    .front .pane-news-panel-pane-5 .view-content .featured-news-teaser {
      background-color: #dddddd; }
      @media screen and (min-width: 992px) {
        .front .pane-news-panel-pane-5 .view-content .featured-news-teaser {
          max-width: 48%;
          margin: .5%; } }
      .front .pane-news-panel-pane-5 .view-content .featured-news-teaser .teaser__detail {
        margin: 0;
        color: #cf4520;
        font-weight: bold;
        width: 100%; }
        .front .pane-news-panel-pane-5 .view-content .featured-news-teaser .teaser__detail::before {
          background-color: #fff;
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
        .front .pane-news-panel-pane-5 .view-content .featured-news-teaser .teaser__detail .teaser-title {
          position: relative;
          font-size: 18px; }
      @media screen and (min-width: 992px) {
        .front .pane-news-panel-pane-5 .view-content .featured-news-teaser {
          display: inline-block;
          width: 49.5%; } }
  .front .section-2 > .row {
    margin: 0; }
    @media screen and (min-width: 992px) {
      .front .section-2 > .row {
        border-top: 2px solid #dddddd;
        border-right: 2px solid #dddddd; } }
  .front .pane-alerts-panel-pane-4 {
    text-align: center; }
    .front .pane-alerts-panel-pane-4 .pane-title {
      color: #fff;
      background-color: #b31b1b;
      padding: 1rem 0; }
      .front .pane-alerts-panel-pane-4 .pane-title::before {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
        content: '\e812 ';
        margin-right: 1rem; }
    .front .pane-alerts-panel-pane-4 .view-content {
      display: inline-block; }
      .front .pane-alerts-panel-pane-4 .view-content .alerts {
        border-bottom: 1px solid #dddddd;
        padding: .75rem 0; }
        .front .pane-alerts-panel-pane-4 .view-content .alerts .views-field-title {
          font-family: "1898Sans-Bold", sans-serif;
          font-size: 18px; }
    .front .pane-alerts-panel-pane-4 .view-footer {
      margin-top: 1.5rem; }
  @media screen and (min-width: 768px) {
    .front .item-list ul .service-category-list {
      margin: 0;
      padding: 0 15px 5px 0; } }
  .front .service-category-list {
    line-height: 1.5;
    font-size: 14px; }
    @media screen and (min-width: 768px) {
      .front .service-category-list {
        width: 50%;
        float: left;
        display: inline; } }
  .front .service-link {
    margin-bottom: 15px; }
  .front .service-link__title {
    font-size: 18px;
    font-weight: 700; }
  .front .section-3 .panel-pane:first-of-type {
    margin-bottom: 1rem; }
  .front .section-3 .pane-bundle-image .fieldable-panels-pane {
    position: relative;
    overflow: hidden; }
    .front .section-3 .pane-bundle-image .fieldable-panels-pane img {
      transition: all 0.1s linear; }
    .front .section-3 .pane-bundle-image .fieldable-panels-pane:hover img {
      transform: scale(1.1); }
    .front .section-3 .pane-bundle-image .fieldable-panels-pane::after {
      display: block;
      content: '';
      padding-top: 75%; }
    .front .section-3 .pane-bundle-image .fieldable-panels-pane .field-basic-image-image img {
      position: absolute;
      top: 0;
      height: 100%;
      object-fit: cover; }
    .front .section-3 .pane-bundle-image .fieldable-panels-pane .field-basic-image-caption {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: #f7f7f7;
      padding-top: 2rem;
      padding-bottom: 2rem; }
      .front .section-3 .pane-bundle-image .fieldable-panels-pane .field-basic-image-caption h5 {
        margin: 0; }
        .front .section-3 .pane-bundle-image .fieldable-panels-pane .field-basic-image-caption h5 a::after {
          content: "\e802";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -1.9rem;
          padding: .6rem 1rem;
          font-size: 2rem;
          background-color: #f7f7f7;
          border-radius: 2rem; }
  .front .footer-section {
    background: #cf4520;
    background-size: cover;
    border-bottom: 1px solid #fff;
    color: #fff;
    margin-top: 30px; }
    .front .footer-section h3 {
      color: #fff;
      text-align: center;
      border-bottom: 1px solid #dddddd;
      padding: 20px 0;
      margin-bottom: 30px; }
      @media screen and (min-width: 992px) {
        .front .footer-section h3 {
          font-size: 30px; } }
    .front .footer-section .fieldable-panels-pane .field-basic-text-text {
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .front .footer-section .fieldable-panels-pane .field-basic-text-text {
          max-width: 70%; } }
      .front .footer-section .fieldable-panels-pane .field-basic-text-text > p {
        font-size: 18px; }
      .front .footer-section .fieldable-panels-pane .field-basic-text-text blockquote {
        color: #fff;
        font-style: italic;
        border: none;
        font-size: 14px;
        font-weight: normal; }
        .front .footer-section .fieldable-panels-pane .field-basic-text-text blockquote > p {
          line-height: 24px; }
  .front footer {
    padding-top: 0; }
